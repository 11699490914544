<template>
  <div class="i18n">
    <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
      {{entry.title}}
    </button>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  name: 'i18n',

  data() {
    return {
      languages: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'es', language: 'es', title: 'Español' },
      ],
    };
  },

  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
  },
};
</script>

<style scoped>

  .i18n {
    position: relative;
    top: -25px;
  }

  .i18n button {
    padding: 5px;
    margin: 5px;
  }

</style>
