import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/vaccine/',
    name: 'HomePage',
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/vaccine/hep-upload',
    name: 'UploadHepVaccinationPage',
    component: () => import('@/views/UploadHepVaccinationPage.vue'),
  },
  {
    path: '/vaccine/upload',
    name: 'UploadVaccinationPage',
    component: () => import('@/views/UploadVaccinationPage.vue'),
  },
  {
    path: '/vaccine/covid-test-upload',
    name: 'UploadCovidTestPage',
    component: () => import('@/views/UploadCovidTestPage.vue'),
  },
  {
    path: '/vaccine/login',
    name: 'LoginPage',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/vaccine/login-email',
    name: 'LoginEmailPage',
    component: () => import('@/views/LoginEmailPage.vue'),
  },
  {
    path: '/vaccine/main-dashboard',
    name: 'MainDashboardPage',
    component: () => import('@/views/MainDashboardPage.vue'),
  },
  {
    path: '/vaccine/error',
    name: 'CustomErrorPage',
    component: () => import('@/views/CustomErrorPage.vue'),
  },
  {
    path: '/vaccine/auth-error',
    name: 'AuthErrorPage',
    component: () => import('@/views/AuthErrorPage.vue'),
  },
  {
    path: '/vaccine/hep',
    name: 'HepProgram_HomePage',
    component: () => import('@/views/hep-program/HomePage.vue'),
  },
  {
    path: '/vaccine/hep/voucher',
    name: 'HepProgram_VoucherPage',
    component: () => import('@/views/hep-program/VoucherPage.vue'),
  },
  {
    path: '/vaccine/hep/vax',
    name: 'HepProgram_VaccinationPage',
    component: () => import('@/views/hep-program/VaccinationPage.vue'),
  },
  {
    path: '/vaccine/hep/test',
    name: 'HepProgram_UploadTestPage',
    component: () => import('@/views/hep-program/UploadTestPage.vue'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "other" */ '@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
