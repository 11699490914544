import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import UUID from 'vue-uuid';
import App from '@/App.vue';
import i18n from '@/plugins/i18n';
import router from '@/router';
import VModal from 'vue-js-modal';
import VueSweetalert2 from 'vue-sweetalert2';
import { VuejsDatatableFactory } from 'vuejs-datatable';

import i18nComponent from '@/components/common/i18n.vue';
import PageHeader from '@/components/common/PageHeader.vue';
import SpinningLoader from '@/components/common/SpinningLoader.vue';
import Privacy from '@/components/common/Privacy.vue';

import '@/scss/normalize.scss';
import '@/scss/webflow.scss';
import '@/scss/main.scss';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-js-modal/dist/styles.css';

Vue.use(VueAxios, axios);
Vue.use(UUID);
Vue.use(VModal);
Vue.use(VueSweetalert2);
Vue.use(VuejsDatatableFactory);

Vue.config.productionTip = false;

Vue.component('i18n-selector', i18nComponent);
Vue.component('zhh-zedic-header', PageHeader);
Vue.component('spinning-loader', SpinningLoader);
Vue.component('privacy-policy', Privacy);

// To avoid circular dependency from Router
global.$router = router;

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');

// eslint-disable-next-line
__webpack_public_path__ = '/vaccine/'
