<template>
  <div class="spinning-loader"></div>
</template>

<script>
export default {
  name: 'SpinningLoader',
};
</script>

<style scoped>
</style>
