<template>
  <div class="footercontainer">
    <center><p class="general-text"><a href="https://www.iubenda.com/privacy-policy/16549631" target="_blank">Privacy Policy</a><br></p></center>
    <center>
      <p class="general-text">
        We do not sell, rent or disclose the information collected on our site to third-parties,
        except to employers when directed by state and CDC guidance in a non-identifiable manner.
        <br>
      </p>
    </center>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
};
</script>

<style scoped>

  .footercontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 16px;
  }

  @media screen and (max-width: 479px) {
    .footercontainer {
      width: 100%;
    }
  }

</style>
