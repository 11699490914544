/* eslint-disable camelcase */
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const en_ErrorMessages = {
  invalidToken: 'Unfortunately your link is no longer valid! For security reasons, you\'ll need to enter your credentials again on the login screen and retrieve a new link 🙂.',
  expiredToken: 'Unfortunately your link has expired! For security reasons, your secure link is only valid for 15 minutes after you receive your email. So you\'ll need to enter your credentials again on the login screen and move a little bit quicker this time 🙂.',
  notFoundToken: 'Unfortunately your link is not valid anymore! For security reasons, your secure link is only valid once after you receive your email. So you\'ll need to enter your credentials again on the login screen.',
};

const es_ErrorMessages = {
  invalidToken: '¡¡Lamentablemente su enlace ya no es válido! Por razones de seguridad, deberá ingresar sus credenciales nuevamente en la pantalla de inicio de sesión y recuperar un nuevo enlace 🙂.',
  expiredToken: '¡Lamentablemente, su enlace ha caducado! Por razones de seguridad, su enlace seguro solo es válido durante 15 minutos después de recibir su correo electrónico. Por lo tanto, deberá ingresar sus credenciales nuevamente en la pantalla de inicio de sesión y moverse un poco más rápido esta vez 🙂.',
  notFoundToken: '¡Lamentablemente, su enlace ya no es válido. Por razones de seguridad, su enlace seguro solo es válido por una vez después de recibir su correo electrónico. Por lo tanto, deberá ingresar sus credenciales nuevamente en la pantalla de inicio de sesión.',
};

const en_LoginPage = {
  loginPage_missingEmployeeId: 'You\'re missing your Employee Id',
  loginPage_missingEmployeeEmail: 'You\'re missing your Employee Email',
  loginPage_notAllowedEmployeeEmailCharacterWarning: 'Emails with "%" or "/" are not allowed.',
};

const es_LoginPage = {
  loginPage_missingEmployeeId: 'Te falta tu número de identificación de empleado ',
  loginPage_missingEmployeeEmail: 'Te falta tu correo electrónico',
  loginPage_notAllowedEmployeeEmailCharacterWarning: 'Los correos electrónicos con "%" o "/"no están permitidos.',
};

const en_HomePage = {
  homePage_pageTitle: 'Welcome to the Vaccination + Test Tracking Portal!',
  homePage_pageTitleHep: 'Select which vaccine you\'d like to create a record for:',
  homePage_pageDescription: 'Please select what you\'re trying to do today:',
  homePage_uploadVaccinationCardOrRecord: 'Upload A Vaccination Card or Record',
  homePage_hepClient_uploadVaccinationCardOrRecord: 'Click or tap here to upload vaccines that cover COVID-19, also known as coronavirus, as well as one or more booster doses you may have received.',
  homePage_hepClient_uploadTestCardOrRecord: 'Click or tap here to upload COVID-19 tests, also known as coronavirus.',
  homePage_hepClient_uploadVaccinationHepCardOrRecord: 'Click or tap here to upload documentation of your Hepatitis A vaccine doses. These vaccines might be called Havrix, Vaqta, or Twinrix on your documentation.',
  homePage_help: 'If you have any questions or issues, or need to upload a vaccination record for an illness not listed here, please email help@zerohourhealth.com',
  homePage_uploadVaccinationCardOrRecordDashboard: 'Vaccination Card Dashboard',
  homePage_uploadWeeklyCovidTest: 'Upload A COVID-19 Test',
  homePage_dashboard: 'Dashboard',
};

const es_HomePage = {
  homePage_pageTitle: '¡Bienvenido al portal de seguimiento de pruebas y vacunación!',
  homePage_pageTitleHep: 'Seleccione para qué vacuna desea crear un registro:',
  homePage_pageDescription: 'Seleccione lo que está intentando hacer hoy:',
  homePage_uploadVaccinationCardOrRecord: 'Cargar una tarjeta o registro de vacunación',
  homePage_hepClient_uploadVaccinationCardOrRecord: 'Haga clic o toque aquí para cargar las vacunas que cubren el COVID-19, también conocido como coronavirus, así como una o más dosis de refuerzo que haya recibido.',
  homePage_hepClient_uploadTestCardOrRecord: 'Haga clic o toque aquí para cargar las pruebas de COVID-19, también conocido como coronavirus.',
  homePage_hepClient_uploadVaccinationHepCardOrRecord: 'Haga clic o toque aquí para cargar la documentación de sus dosis de vacuna contra la hepatitis A. Estas vacunas pueden llamarse Havrix, Vaqta o Twinrix en su documentación.',
  homePage_help: 'Si tiene alguna pregunta o problema, o necesita cargar un registro de vacunación para una enfermedad que no figura aquí, envíe un correo electrónico a help@zerohourhealth.com',
  homePage_uploadVaccinationCardOrRecordDashboard: 'Tablero de tarjeta de vacunación',
  homePage_uploadWeeklyCovidTest: 'Cargar una prueba de COVID-19',
  homePage_dashboard: 'Tablero',
};

const en_UploadVaccinationPage = {
  uploadVaccinationPage_covidVaccinationRecordTitle: 'Upload a COVID-19 Vaccination Record',
  uploadVaccinationPage_pageDescription: 'Please enter your vaccination information below.',
  uploadVaccinationPage_managerPageDescription: 'To upload a COVID-19 vaccination record, please select your name or the name of your employee from the drop-down list. Once a name is selected, complete the vaccination record information.',
  uploadVaccinationPage_employeeEmailLabel: 'Employee Email',
  uploadVaccinationPage_vaccinationCount: 'Vaccination Count',
  uploadVaccinationPage_vaccinationCountDescription: 'This is the total number of employees vaccinated at your specific workplace.',
  uploadVaccinationPage_vaccinationCountDescriptionFlynnrg: 'You have the right to request information surrounding the aggregate number of fully vaccinated employees at your workplace.',
  uploadVaccinationPage_showFullyVaccinatedCount: 'Click here to run employee vaccination report.',
  uploadVaccinationPage_employeesLabel: 'Employees',
  uploadVaccinationPage_enterNameAndRestaurantText: 'Please enter your name and then select the restaurant you work at from the list:',
  uploadVaccinationPage_enterNameAndLocationText: 'Please enter your name and then select the location you work at from the list:',
  uploadVaccinationPage_enterNameAndDepartmentText: 'Please enter your name and then select the department you work in from the list:',
  uploadVaccinationPage_selectRestaurantLabel: 'Select Your Restaurant',
  uploadVaccinationPage_selectRestaurantLabelForBlaze: 'Select Your Location',
  uploadVaccinationPage_selectDepartmentLabel: 'Select Your Department',
  uploadVaccinationPage_firstNameMissingTitle: 'You\'re missing your First Name',
  uploadVaccinationPage_firstNameMissingText: 'Please enter your First Name',
  uploadVaccinationPage_lastNameMissingTitle: 'You\'re missing your Last Name',
  uploadVaccinationPage_lastNameMissingText: 'Please enter your Last Name',
  uploadVaccinationPage_restaurantMissingTitle: 'You\'re missing your Restaurant',
  uploadVaccinationPage_restaurantMissingText: 'Please enter your Restaurant',
  uploadVaccinationPage_addThirdOrBosterDose: 'If you\'ve received a booster dose, select the brand and date:',
  uploadVaccinationPage_addBosterDose: 'Add a booster dose',
  uploadVaccinationPage_uploadCovidTest: 'Upload A COVID Test',
};

const es_UploadVaccinationPage = {
  uploadVaccinationPage_covidVaccinationRecordTitle: 'Cargue un registro de vacunación contra el COVID-19',
  uploadVaccinationPage_pageDescription: 'Ingrese su información de vacunación a continuación.',
  uploadVaccinationPage_managerPageDescription: 'Para cargar un registro de vacunación de COVID-19, seleccione su nombre o el nombre de su empleado de la lista desplegable. Una vez seleccionado un nombre, complete la información del registro de vacunación.',
  uploadVaccinationPage_employeeEmailLabel: 'Correo electrónico',
  uploadVaccinationPage_vaccinationCount: 'Recuento de vacunación',
  uploadVaccinationPage_vaccinationCountDescription: 'Este es el número total de empleados vacunados en su lugar de trabajo específico.',
  uploadVaccinationPage_vaccinationCountDescriptionFlynnrg: 'Tiene derecho a solicitar información sobre el número total de empleados completamente vacunados en su lugar de trabajo.',
  uploadVaccinationPage_showFullyVaccinatedCount: 'Clic aquí para ejecutar el informe de vacunación de los empleados.',
  uploadVaccinationPage_employeesLabel: 'Empleados',
  uploadVaccinationPage_enterNameAndRestaurantText: 'Ingrese su nombre y luego seleccione el restaurante en el que trabaja de la lista:',
  uploadVaccinationPage_enterNameAndLocationText: 'Ingrese su nombre y luego seleccione la ubicación en el que trabaja de la lista:',
  uploadVaccinationPage_enterNameAndDepartmentText: 'Ingrese su nombre y luego seleccione el departamento en el que trabaja de la lista:',
  uploadVaccinationPage_selectRestaurantLabel: 'Seleccione tu restaurante',
  uploadVaccinationPage_selectRestaurantLabelForBlaze: 'Selecciona tu ubicación',
  uploadVaccinationPage_selectDepartmentLabel: 'Selecciona tu departamento',
  uploadVaccinationPage_firstNameMissingTitle: 'Te falta tu primer nombre',
  uploadVaccinationPage_firstNameMissingText: 'Por favor, introduzca tu primer nombre',
  uploadVaccinationPage_lastNameMissingTitle: 'Te falta tu apellido',
  uploadVaccinationPage_lastNameMissingText: 'Por favor, introduzca tu apellido',
  uploadVaccinationPage_restaurantMissingTitle: 'Te falta tu restaurante',
  uploadVaccinationPage_restaurantMissingText: 'Por favor, introduzca tu restaurante',
  uploadVaccinationPage_addThirdOrBosterDose: 'Si ha recibido una dosis de refuerzo, seleccione la marca y la fecha:',
  uploadVaccinationPage_addBosterDose: 'Agregue una dosis de refuerzo',
  uploadVaccinationPage_uploadCovidTest: 'Cargar una prueba de COVID',
};

const en_UploadHepVaccinationPage = {
  uploadHepVaccinationPage_pageTitle: 'Hepatitis A Vaccination Record',
  uploadHepVaccinationPage_employeeEmailLabel: 'Employee Email',
  uploadHepVaccinationPage_hepDescription: 'Hepatitis A vaccines are made by three different brands, and their names are Havrix, Vaqta, or Twinrix. Havrix and Vaqta require two doses, 6-12 months apart. Twinrix is a three dose vaccination that covers both Hepatitis A and B.',
  uploadHepVaccinationPage_enterNameAndRestaurantText: 'Please enter your name and then select the restaurant you work at from the list:',
  uploadHepVaccinationPage_enterNameAndLocationText: 'Please enter your name and then select the location you work at from the list:',
  uploadHepVaccinationPage_enterNameAndDepartmentText: 'Please enter your name and then select the department you work in from the list:',
  uploadHepVaccinationPage_selectRestaurantLabel: 'Select Your Restaurant',
  uploadHepVaccinationPage_selectRestaurantLabelForBlaze: 'Select Your Location',
  uploadHepVaccinationPage_selectDepartmentLabel: 'Select Your Department',
  uploadHepVaccinationPage_firstNameMissingTitle: 'You\'re missing your First Name',
  uploadHepVaccinationPage_firstNameMissingText: 'Please enter your First Name',
  uploadHepVaccinationPage_lastNameMissingTitle: 'You\'re missing your Last Name',
  uploadHepVaccinationPage_lastNameMissingText: 'Please enter your Last Name',
  uploadHepVaccinationPage_restaurantMissingTitle: 'You\'re missing your Restaurant',
  uploadHepVaccinationPage_restaurantMissingText: 'Please enter your Restaurant',
  uploadHepVaccinationPage_addBosterDose: 'Add another dose',
  uploadHepVaccinationPage_selectBrandAndDate_firstDose: 'Select the brand and date of your first dose:',
  uploadHepVaccinationPage_selectBrandAndDate_secondDose: 'If you\'ve received your second dose, select the brand and date:',
  uploadHepVaccinationPage_selectBrandAndDate_thirdDose: 'If you\'ve received a third dose, select the brand and date of your third dose:',
  uploadHepVaccinationPage_selectBrandAndDate_fourthDose: 'If you\'ve received any additional doses, select the brand and date of your additional (fourth) dose:',
  uploadHepVaccinationPage_selectBrandAndDate_fifthDose: 'Select the brand and date of your fifth dose:',
  uploadHepVaccinationPage_selectBrandAndDate_sixthDose: 'Select the brand and date of your sixth dose:',
  uploadHepVaccinationPage_saveYourChanges: 'UPLOAD VACCINE DOCUMENTATION',
};

const es_UploadHepVaccinationPage = {
  uploadHepVaccinationPage_pageTitle: 'Registro de vacunación contra la hepatitis A',
  uploadHepVaccinationPage_employeeEmailLabel: 'Correo electrónico',
  uploadHepVaccinationPage_hepDescription: 'Las vacunas contra la hepatitis A están fabricadas por tres marcas diferentes y sus nombres son Havrix, Vaqta o Twinrix. Havrix y Vaqta requieren dos dosis, con 6 a 12 meses de diferencia. Twinrix es una vacuna de tres dosis que cubre tanto la hepatitis A como la B.',
  uploadHepVaccinationPage_enterNameAndRestaurantText: 'Ingrese su nombre y luego seleccione el restaurante en el que trabaja de la lista:',
  uploadHepVaccinationPage_enterNameAndLocationText: 'Ingrese su nombre y luego seleccione la ubicación en el que trabaja de la lista:',
  uploadHepVaccinationPage_enterNameAndDepartmentText: 'Ingrese su nombre y luego seleccione el departamento en el que trabaja de la lista:',
  uploadHepVaccinationPage_selectRestaurantLabel: 'Seleccione tu restaurante',
  uploadHepVaccinationPage_selectRestaurantLabelForBlaze: 'Selecciona tu ubicación',
  uploadHepVaccinationPage_selectDepartmentLabel: 'Selecciona tu departamento',
  uploadHepVaccinationPage_firstNameMissingTitle: 'Te falta tu primer nombre',
  uploadHepVaccinationPage_firstNameMissingText: 'Por favor, introduzca tu primer nombre',
  uploadHepVaccinationPage_lastNameMissingTitle: 'Te falta tu apellido',
  uploadHepVaccinationPage_lastNameMissingText: 'Por favor, introduzca tu apellido',
  uploadHepVaccinationPage_restaurantMissingTitle: 'Te falta tu restaurante',
  uploadHepVaccinationPage_restaurantMissingText: 'Por favor, introduzca tu restaurante',
  uploadHepVaccinationPage_addBosterDose: 'Agregue otra dosis',
  uploadHepVaccinationPage_selectBrandAndDate_firstDose: 'Seleccione la marca y la fecha de la primera dosis:',
  uploadHepVaccinationPage_selectBrandAndDate_secondDose: 'Si ha recibido su segunda dosis, seleccione la marca y la fecha:',
  uploadHepVaccinationPage_selectBrandAndDate_thirdDose: 'Si ha recibido una tercera dosis, seleccione la marca y la fecha de su tercera dosis:',
  uploadHepVaccinationPage_selectBrandAndDate_fourthDose: 'Si ha recibido alguna dosis adicional, seleccione la marca y la fecha de su (cuarta) dosis adicional:',
  uploadHepVaccinationPage_selectBrandAndDate_fifthDose: 'Seleccione la marca y la fecha de la quinta dosis:',
  uploadHepVaccinationPage_selectBrandAndDate_sixthDose: 'Seleccione la marca y la fecha de la sexta dosis:',
  uploadHepVaccinationPage_saveYourChanges: 'SUBIR DOCUMENTACIÓN DE VACUNA',
};

const en_UploadCovidTestPage = {
  uploadCovidTestPage_covidTestRecordTitle: 'Upload a COVID-19 Test Result',
  uploadCovidTestPage_pageDescription: 'Please select the date of your most recent COVID-19 test, and then upload a picture of your test result.',
  uploadCovidTestPage_pageAdvisorySodexo: 'If you work at a Healthcare or Senior Living location, or other location that mandates vaccination only, you can\'t choose the testing option unless you have a documented approved accomodation.',
  uploadCovidTestPage_managerPageDescription: 'To upload a COVID-19 test result, please select your name or the name of your employee from the drop-down list. Once a name is selected, complete the test result information.',
  uploadCovidTestPage_adminScreenWarning: 'This is an administrator screen for manually entering covid test verification for your employees. If you are not a manager, or you do not want to be viewing this screen, click here to return to the login screen.',
  uploadCovidTestPage_enterNameAndRestaurantText: 'Please enter your name and then select the restaurant you work at from the list:',
  uploadCovidTestPage_enterNameAndLocationText: 'Please enter your name and then select the location you work at from the list:',
  uploadCovidTestPage_enterNameAndDepartmentText: 'Please enter your name and then select the department you work in from the list:',
  uploadCovidTestPage_selectCovidTestDate: 'Select the date of your most recent COVID-19 test:',
  uploadCovidTestPage_selectCovidTestResult: 'Was your test <span style="color: red">POSITIVE</span> or <span style="color: green">NEGATIVE</span>?',
  uploadCovidTestPage_moreInfo: 'We need a bit more info...',
  uploadCovidTestPage_moreInfoDesc: 'It looks like you\'ve never filled out a wellness check before, and we need to make these two systems match!',
  uploadCovidTestPage_moreInfoBestContact: 'Please provide the best mobile phone and email addresses to contact you at.',
  uploadCovidTestPage_moreInfoBestContactDesc: 'You\'ll see same phone number and email for your employee wellness checks.',
  uploadCovidTestPage_contactEmail: 'Contact Email:',
  uploadCovidTestPage_contactEmailInfo: 'We use this email address to send you a notification when your next test is due.',
  uploadCovidTestPage_contactEmailPlaceholder: 'Enter your email...',
  uploadCovidTestPage_contactPhoneNumberInfo: 'We use this number to send you a notification with further instructions.',
  uploadCovidTestPage_contactPhoneNumberPlaceholder: 'Enter your phone number...',
  uploadCovidTestPage_informContactEmail: 'Please inform a valid Contact Email...',
  uploadCovidTestPage_uploadCovidTestCopyTitle: 'Upload a copy of your test results:',
  uploadCovidTestPage_uploadCovidTestCopyBelowTitle: 'Now upload a PDF of your test result or a picture of your rapid test below:',
  uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg1: 'Your Sars-COV-2 test must be cleared, approved, or authorized, including in an Emergency Use Authorization (EUA), by the U.S. Food and Drug Administration (FDA) to detect current infection with the SARS-CoV-2 virus (e.g., a viral test). It must be administered in accordance with the authorized instructions; and not both self-administered and self-read unless observed by the employer or an authorized telehealth proctor.',
  uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg2: 'Examples of tests that satisfy this requirement: tests that are processed by a laboratory (including home or on-site collected specimens), proctored over-the-counter tests, point of care tests, and tests where specimen collection and processing is either done or observed by an employer.',
  uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg3: 'For a copy of Flynn Restaurant Group’s Workplace Privacy Notice, please visit ',
  uploadCovidTestPage_submitLabel: 'SUBMIT YOUR TEST',
  uploadCovidTestPage_firstNameMissingTitle: 'You\'re missing your First Name',
  uploadCovidTestPage_firstNameMissingText: 'Please enter your First Name',
  uploadCovidTestPage_lastNameMissingTitle: 'You\'re missing your Last Name',
  uploadCovidTestPage_lastNameMissingText: 'Please enter your Last Name',
  uploadCovidTestPage_restaurantMissingTitle: 'You\'re missing your Restaurant',
  uploadCovidTestPage_restaurantMissingText: 'Please enter your Restaurant',
  uploadCovidTestPage_submitSuccess: 'Your covid test has been logged in the system.',
  uploadCovidTestPage_pleaseSelectCovidTestDate: 'Please select the Test Date!',
  uploadCovidTestPage_pleaseSelectCovidTestResult: 'Please select the Test Result!',
  uploadCovidTestPage_missingCard: 'You\'re missing your covid test!',
  uploadCovidTestPage_mustUpload: 'You must upload a picture or PDF of your covid test',
};

const es_UploadCovidTestPage = {
  uploadCovidTestPage_covidTestRecordTitle: 'Cargar um resultado de teste COVID-19',
  uploadCovidTestPage_pageDescription: 'Seleccione la fecha de la prueba COVID-19 más reciente y luego cargue una imagen de los resultados de su sitio de prueba o el casete de prueba rápida.',
  uploadCovidTestPage_pageAdvisorySodexo: 'Si trabaja en un lugar de atención médica o de vivienda para personas de la tercera edad, u otro lugar que exija únicamente la vacunación, no puede elegir la opción de prueba a menos que tenga una adaptacin aprobada y documentada.',
  uploadCovidTestPage_managerPageDescription: 'Para fazer upload de um resultado de teste COVID-19, selecione seu nome ou o nome do seu funcionário na lista suspensa. Assim que um nome for selecionado, preencha as informações do resultado do teste.',
  uploadCovidTestPage_adminScreenWarning: 'Esta es una pantalla de administrador para ingresar manualmente la verificación de prueba de covid para sus empleados. Si Ud. no es administrador o no desea ver esta pantalla, haga clic aquí para volver a la pantalla de inicio de sesión.',
  uploadCovidTestPage_enterNameAndRestaurantText: 'Ingrese su nombre y luego seleccione el restaurante en el que trabaja de la lista:',
  uploadCovidTestPage_enterNameAndLocationText: 'Ingrese su nombre y luego seleccione la ubicación en el que trabaja de la lista:',
  uploadCovidTestPage_enterNameAndDepartmentText: 'Ingrese su nombre y luego seleccione el departamento en el que trabaja de la lista:',
  uploadCovidTestPage_selectCovidTestDate: 'Seleccione la fecha de su prueba COVID-19 más reciente:',
  uploadCovidTestPage_selectCovidTestResult: '¿Fue su prueba <span style="color: red">POSITIVA</span> o <span style="color: green">NEGATIVA</span>?',
  uploadCovidTestPage_moreInfo: 'Necesitamos un poco más de información...',
  uploadCovidTestPage_moreInfoDesc: 'Parece que nunca antes ha completado un chequeo de bienestar, ¡y necesitamos hacer que estos dos sistemas coincidan!',
  uploadCovidTestPage_moreInfoBestContact: 'Proporcione el mejor teléfono móvil y direcciones de correo electrónico para contactarlo.',
  uploadCovidTestPage_moreInfoBestContactDesc: 'Verá el mismo número de teléfono y correo electrónico para las comprobaciones de bienestar de sus empleados.',
  uploadCovidTestPage_contactEmail: 'Email de contacto:',
  uploadCovidTestPage_contactEmailInfo: 'Usamos esta dirección de correo electrónico para enviarle una notificación cuando vence su próxima prueba.',
  uploadCovidTestPage_contactEmailPlaceholder: 'Escriba su correo electrónico',
  uploadCovidTestPage_contactPhoneNumberInfo: 'Utilizamos este número para enviarle una notificación con más instrucciones..',
  uploadCovidTestPage_contactPhoneNumberPlaceholder: 'Ingrese su número telefónico...',
  uploadCovidTestPage_informContactEmail: 'Por favor, informe un correo electrónico de contacto válido...',
  uploadCovidTestPage_uploadCovidTestCopyTitle: 'Ahora cargue un PDF del resultado de su prueba o una imagen de su prueba rápida:',
  uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg1: 'Su prueba del Sars-COV-2 debe ser aprobada o autorizada por Autorización de Uso de Emergencia (EUA, por sus siglas en inglés) de la U.S. Food and Drug Administration (FDA, por sus siglas en inglés) para la detección de infección activa con el virus Sars-COV-2 (ósea, una prueba viral). Debe ser administrada de acuerdo con las instrucciones autorizadas; y no debe ser ambo autoadministrada y auto-interpretada al menos que le observe su patrono o un evaluador de telemedicina aprobado.',
  uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg2: 'Ejemplo de exámenes que satisfacen estos requerimientos: exámenes procesados en un laboratorio (incluye especímenes recolectados en casa y en el laboratorio), exámenes autoadministrados con un evaluador aprobado, exámenes en una clínica o hospital y exámenes en los que la recolección del espécimen fue observada o hecha por su patrono.',
  uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg3: 'Para una copia de Flynn Restaurant Group’s Noticia de Privacidad en el Trabajo, visite:',
  uploadCovidTestPage_uploadCovidTestCopyBelowTitle: 'Ahora cargue un PDF del resultado de su prueba o una imagen de su prueba rápida abajo:',
  uploadCovidTestPage_submitLabel: 'ENVÍA TU PRUEBA',
  uploadCovidTestPage_firstNameMissingTitle: 'Te falta tu primer nombre',
  uploadCovidTestPage_firstNameMissingText: 'Por favor, introduzca tu primer nombre',
  uploadCovidTestPage_lastNameMissingTitle: 'Te falta tu apellido',
  uploadCovidTestPage_lastNameMissingText: 'Por favor, introduzca tu apellido',
  uploadCovidTestPage_restaurantMissingTitle: 'Te falta tu restaurante',
  uploadCovidTestPage_restaurantMissingText: 'Por favor, introduzca tu restaurante',
  uploadCovidTestPage_submitSuccess: 'Su prueba de covid se ha registrado en el sistema.',
  uploadCovidTestPage_pleaseSelectCovidTestDate: '¡Por favor, seleccione la fecha de la prueba!',
  uploadCovidTestPage_pleaseSelectCovidTestResult: 'Por favor, seleccione el resultado de la prueba!',
  uploadCovidTestPage_missingCard: '¡Falta su prueba de covid!',
  uploadCovidTestPage_mustUpload: 'Debe cargar una imagen o PDF de su prueba de covid',
};

const en_CovidVaxNoRecordUpload = {
  covidVaxNoRecordUpload_title: 'You have not uploaded a vaccine record yet!',
  covidVaxNoRecordUpload_first: 'When you get vaccinated, <strong>you won\'t need to do regular COVID testing anymore!</strong>',
  covidVaxNoRecordUpload_second: 'So please go get your COVID-19 vaccine, it\'s free!',
  covidVaxNoRecordUpload_third: 'If you have already been vaccinated, please click the button below to upload your vaccine details:',
  covidVaxNoRecordUpload_button: 'Upload A Vaccination Card',
};

const es_CovidVaxNoRecordUpload = {
  covidVaxNoRecordUpload_title: 'Aún no ha subido un registro de vacuna',
  covidVaxNoRecordUpload_first: 'Cuando se vacune, <strong>¡ya no necesitará hacerse pruebas de COVID periódico!</strong>',
  covidVaxNoRecordUpload_second: 'Así que, por favor, obtenga su vacuna COVID-19, ¡es gratis!',
  covidVaxNoRecordUpload_third: 'Si ya ha sido vacunado, haga clic en el botón a continuación para cargar los detalles de su vacuna:',
  covidVaxNoRecordUpload_button: 'Cargar una tarjeta de vacunación',
};

const en_DashboardPage = {
  dashboardPage_banner: 'Some vaccination status definitions have changed to give you more specific information.',
  dashboardPage_bannerLink: 'Click here for a full breakdown of the changes!',
  dashboardPage_selectEmployeeLabel: 'Filter by employee...',
  dashboardPage_noResults: 'No results found',
  dashboardPage_logout: 'Log out',
};

const es_DashboardPage = {
  dashboardPage_banner: 'Algunas definiciones de estado de vacunación han cambiado para brindarle información más específica.',
  dashboardPage_bannerLink: '¡Haga clic aquí para ver un desglose completo de los cambios!',
  dashboardPage_selectEmployeeLabel: 'Filtrar por empleado',
  dashboardPage_noResults: 'No se han encontrado resultados',
  dashboardPage_logout: 'Cerrar sesión',
};

const en_NavigationMenu = {
  navigationMenu_goToUploadVaccinationPage: 'Upload a Vaccination Record',
  navigationMenu_goToUploadCovidTestPage: 'Upload a Covid-19 Test',
  navigationMenu_goToHistoryCovidTestPage: 'View COVID Test History',
  navigationMenu_goToHistoryCovidTestByEmployeePage: 'View Test History By Employee',
  navigationMenu_goToWellnessCheckDashboard: 'Wellness Check Dash',
  navigationMenu_goToMainMenu: 'Back to Main Menu',
  navigationMenu_goToBack: 'Go Back',
};

const es_NavigationMenu = {
  navigationMenu_goToUploadVaccinationPage: 'Cargar un registro de vacunación',
  navigationMenu_goToUploadCovidTestPage: 'Cargar una prueba de COVID-19',
  navigationMenu_goToHistoryCovidTestPage: 'Ver historial de pruebas COVID',
  navigationMenu_goToHistoryCovidTestByEmployeePage: 'Ver historial de pruebas COVID por empleado',
  navigationMenu_goToWellnessCheckDashboard: 'Wellness Check Tablero',
  navigationMenu_goToMainMenu: 'Regresar al menú principal',
  navigationMenu_goToBack: 'Regresar',
};

const en_HepProgramPages = {
  hepProgram_homePage_title: 'Vaccine Portal',
  hepProgram_homePage_pageDescription: 'Welcome to the Zero Hour Health vaccine portal! This is the place where you can learn how to find your vaccine/immunization records, upload them to our system, or request a <span style="color: #5031D0;">convenient, free vaccine courtesy </span> of CFA.',
  hepProgram_homePage_careAboutTitle: 'Why do you care about Hepatitis A?',
  hepProgram_homePage_careAboutDescription: 'Hepatitis A is a very contagious virus that can make you sick for months and cause permanent liver damage. Most people got a Hepatitis A vaccine when they went to daycare, elementary school, or college, but not everyone. If someone comes to work with Hepatitis A, their fellow team members <strong>may not be able to work for as many as 55 days!</strong>',
  hepProgram_homePage_findVaxTitle: 'Where can I find my vaccine records?',
  hepProgram_homePage_findVaxDescription: 'There are a number of places you might find your records: <ul><li>Ask a parent or guardian</li><li>Call yourpediatrician\'s office</li><li>Call your elementary school administrator\'s office</li><li>The health department where you lived growing up might have records as well, just Google "[your state] immunization records." The State may guide you to your county\'s website, that works too!</li></ul>',
  hepProgram_homePage_unsureVaxTitle: 'What if I\'m not sure if I got a vaccine or not?',
  hepProgram_homePage_unsureVaxDescription: 'If you’d like to have one, we’ve got you! If you are unsure whether you have been vaccinated, you can receive another dose. You can get vaccinated <strong>for free</strong> through Zero Hour Health and your local Walmart Pharmacy.',
  hepProgram_homePage_mandatoryTitle: 'Is this mandatory?',
  hepProgram_homePage_mandatoryDescription: 'No. We have partnered with Zero Hour Health for you to voluntarily submit your Hepatitis A vaccination records if you have already been vaccinated, and for everyone else, provide you with easy ways to get a Hepatitis A vaccine <strong>for free if you choose to do so.</strong>',
  hepProgram_homePage_testedPositiveTitle: 'What if I’ve tested positive for Hepatitis A in the past?',
  hepProgram_homePage_testedPositiveDescription: 'If you have tested positive for Hep A in the past, you don’t need to get another vaccine, you are immune! We do need to see proof of this positive Hepatitis A test or a test that shows you have Hepatitis A Antibodies (AB).',
  hepProgram_homePage_uploadPositiveTest: 'UPLOAD POSITIVE TEST',
  hepProgram_homePage_uploadVax: 'UPLOAD VACCINE RECORD',
  hepProgram_homePage_requestVoucher: 'REQUEST A WALMART VOUCHER',
  // Voucher request
  hepProgram_voucherPage_title: 'Voucher Request',
  hepProgram_voucherPage_pageDescription: 'Scroll down to save our voucher for a <span style="color: #5031D0;">convenient, free Hepatitis A</span> vaccine courtesy of CFA and Zero Hour Health. This voucher can be redeemed at no-cost to you at any Walmart Pharmacy in the U.S. You can find your nearest <a target="_blank" href="https://www.walmart.com/store-finder?services=pharmacy">Walmart Pharmacy here</a>.',
  hepProgram_voucherPage_voucherInfo: 'No need to print this voucher, you can just show them the saved image or screenshot on your phone.',
  hepProgram_voucherPage_savePhoto: 'DOWNLOAD VOUCHER',
  // Vax Upload
  hepProgram_vaxPage_title: 'Vaccine Portal',
  hepProgram_vaxPage_pageDescription: 'Hepatitis vaccines can come in one or two doses. There\'s even a version that is a combo Hep A/B that has three doses. So please tell us how many doses you\'ve got?',
  hepProgram_vaxPage_selectDosis: 'Select the number of doses...',
  hepProgram_vaxPage_informDates: 'Now we need you to tell us the dates of the dose(s):',
  hepProgram_vaxPage_dose: 'Dose',
  hepProgram_vaxPage_uploadVax: 'Upload a copy of your vaccination documentation:',
  hepProgram_vaxPage_saveYourChanges: 'UPLOAD',
  hepProgram_vaxPage_submitConsent: 'By submitting this form, I hereby consent to voluntarily share my vaccination records with Zero Hour Health. I understand and acknowledge that providing this information is entirely voluntary and not a condition of my employment or continued employment. I understand that this information will be handled confidentially by Zero Hour Health in accordance with applicable laws and regulations.',
  hepProgram_vaxPage_vaccinationRecords: 'Vaccination Records',
  // Test Upload
  hepProgram_testPage_title: 'Vaccine Portal',
  hepProgram_testPage_pageDescription: 'If you have tested positive for Hep A in the past, you don’t need to get another vaccine, you are immune! We do need to see proof of this positive Hepatitis A test or a test that shows you have Hepatitis A Antibodies (AB).',
  hepProgram_testPage_informDates: 'Tell us the date on your positive test result:',
  hepProgram_testPage_uploadTest: 'Upload a copy of your positive test:',
  hepProgram_testPage_saveYourChanges: 'UPLOAD',
  hepProgram_testPage_submitConsent: 'By submitting this form, I hereby consent to voluntarily share my vaccination records with Zero Hour Health. I understand and acknowledge that providing this information is entirely voluntary and not a condition of my employment or continued employment. I understand that this information will be handled confidentially by Zero Hour Health in accordance with applicable laws and regulations.',
  hepProgram_testPage_testRecords: 'Test Records',
};

const es_HepProgramPages = {
  hepProgram_homePage_title: 'Portal de Vacunas',
  hepProgram_homePage_pageDescription: '¡Bienvenido al portal de vacunas de Zero Hour Health! Este es el lugar donde puede aprender cómo encontrar sus registros de vacunas/inmunizaciones, cargarlos en nuestro sistema o solicitar una <span style="color: #5031D0;">vacuna conveniente y gratuita</span>, cortesía de CFA.',
  hepProgram_homePage_careAboutTitle: '¿Por qué le importa la hepatitis A?',
  hepProgram_homePage_careAboutDescription: 'La hepatitis A es un virus muy contagioso que puede enfermarlo durante meses y causar daño hepático permanente. La mayoría de las personas recibieron la vacuna contra la hepatitis A cuando iban a la guardería, la escuela primaria o la universidad, pero no todas. Si alguien viene a trabajar con hepatitis A,<strong> es posible que sus compañeros de equipo no puedan trabajar hasta por 55 días.</strong>',
  hepProgram_homePage_findVaxTitle: '¿Dónde puedo encontrar mis registros de vacunas?',
  hepProgram_homePage_findVaxDescription: 'Hay varios lugares donde puede encontrar sus registros:<ul><li>Pregúntele a un padre o tutor</li><li>Llame al consultorio de su pediatra.</li><li>Llame a la oficina del administrador de su escuela primaria.</li><li>Es posible que el departamento de salud donde usted vivió cuando era niño también tenga registros; simplemente busque en Google “registros de vacunación [de su estado]”. El Estado puede guiarlo al sitio web de su condado, ¡eso también funciona!</li></ul>',
  hepProgram_homePage_unsureVaxTitle: '¿Qué pasa si no estoy seguro de si me vacuné o no?',
  hepProgram_homePage_unsureVaxDescription: 'Si quieres tener uno, ¡te tenemos! Si no está seguro de haber sido vacunado, puede recibir otra dosis. Puede vacunarse de forma <strong>gratuita</strong> a través de Zero Hour Health y su farmacia Walmart local.',
  hepProgram_homePage_mandatoryTitle: '¿Es esto obligatorio?',
  hepProgram_homePage_mandatoryDescription: 'No. Nos hemos asociado con Zero Hour Health para que usted envíe voluntariamente sus registros de vacunación contra la hepatitis A si ya ha sido vacunado, y para todos los demás, le brindamos formas sencillas de obtener la vacuna contra la hepatitis A <strong>de forma gratuita si decide hacerlo.</strong>',
  hepProgram_homePage_testedPositiveTitle: '¿Qué pasa si he dado positivo en la prueba de hepatitis A en el pasado?',
  hepProgram_homePage_testedPositiveDescription: 'Si su prueba de hepatitis A dio positivo en el pasado, no necesita recibir otra vacuna, ¡usted es inmune! Necesitamos ver prueba de esta prueba positiva de hepatitis A o una prueba que demuestre que tiene anticuerpos contra la hepatitis A (AB).',
  hepProgram_homePage_uploadPositiveTest: 'SUBIR PRUEBA POSITIVA',
  hepProgram_homePage_uploadVax: 'SUBIR REGISTRO DE VACUNAS',
  hepProgram_homePage_requestVoucher: 'SOLICITAR UN VOUCHER DE WALMART',
  // Voucher request
  hepProgram_voucherPage_title: 'Solicitud de vale',
  hepProgram_voucherPage_pageDescription: 'Desplácese hacia abajo para guardar nuestro cupón para una vacuna contra la <span style="color: #5031D0;">hepatitis A conveniente y gratuita</span>, cortesía de CFA y Zero Hour Health. Este cupón se puede canjear sin costo para usted en cualquier farmacia Walmart en los EE. UU. Puede encontrar su <a target="_blank" href="https://www.walmart.com/store-finder?services=pharmacy">farmacia Walmart más cercana aquí</a>.',
  hepProgram_voucherPage_voucherInfo: 'No es necesario imprimir este cupón, simplemente puede mostrarles la imagen guardada o la captura de pantalla en su teléfono.',
  hepProgram_voucherPage_savePhoto: 'DESCARGAR VOUCHER',
  // Vax Upload
  hepProgram_vaxPage_title: 'Portal de Vacunas',
  hepProgram_vaxPage_pageDescription: 'Las vacunas contra la hepatitis pueden venir en una o dos dosis. Incluso hay una versión que es una combinación Hep A/B que tiene tres dosis. Entonces, díganos cuántas dosis tiene.',
  hepProgram_vaxPage_selectDosis: 'Seleccione el número de dosis...',
  hepProgram_vaxPage_informDates: 'Ahora necesitamos que nos diga las fechas de la(s) dosis:',
  hepProgram_vaxPage_dose: 'Dosis',
  hepProgram_vaxPage_uploadVax: 'Sube una copia de tu documentación de vacunación:',
  hepProgram_vaxPage_saveYourChanges: 'UPLOAD',
  hepProgram_vaxPage_submitConsent: 'Al enviar este formulario, por la presente doy mi consentimiento para compartir voluntariamente mis registros de vacunación con Zero Hour Health. Entiendo y reconozco que proporcionar esta información es completamente voluntario y no es una condición para mi empleo o mi continuidad laboral. Entiendo que Zero Hour Health manejará esta información de manera confidencial de acuerdo con las leyes y regulaciones aplicables.',
  hepProgram_vaxPage_vaccinationRecords: 'Registros de vacunación',
  // Test Upload
  hepProgram_testPage_title: 'Portal de Vacunas',
  hepProgram_testPage_pageDescription: 'Si su prueba de hepatitis A dio positivo en el pasado, no necesita recibir otra vacuna, ¡usted es inmune! Necesitamos ver prueba de esta prueba positiva de hepatitis A o una prueba que demuestre que tiene anticuerpos contra la hepatitis A (AB).',
  hepProgram_testPage_informDates: 'Díganos la fecha del resultado positivo de su prueba:',
  hepProgram_testPage_uploadTest: 'Cargue una copia de su prueba positiva:',
  hepProgram_testPage_saveYourChanges: 'UPLOAD',
  hepProgram_testPage_submitConsent: 'Al enviar este formulario, por la presente doy mi consentimiento para compartir voluntariamente mis registros de vacunación con Zero Hour Health. Entiendo y reconozco que proporcionar esta información es completamente voluntario y no es una condición para mi empleo o mi continuidad laboral. Entiendo que Zero Hour Health manejará esta información de manera confidencial de acuerdo con las leyes y regulaciones aplicables.',
  hepProgram_testPage_testRecords: 'Registros de prueba',
};

const messages = {
  en: {
    // Upload page without vaccine
    employeeNameAndID: 'Employee Name + ID:',
    employeeEmailLabel: 'Employee Email',
    uploadAdminScreenWarning: 'This is an administrator screen for manually entering verification for your employees. If you are not a manager, or you do not want to be viewing this screen',
    clickHereToLogin: 'click here to return to the login screen.',
    incorrectUserWarningLink1: 'If you are not',
    incorrectUserWarningLink2_employeeId: 'it means you entered an incorrect employee ID number.',
    incorrectUserWarningLink2_employeeIdSodexo: 'it means you entered an incorrect employee ID and birth year.',
    incorrectUserWarningLink2_employeeEmail: 'Check the email address you entered.',
    incorrectUserWarningLink3_employeeId: 'Click here to try logging in again.',
    incorrectUserWarningLink3_employeeEmail: 'If it is not correct, please click here to go back and start again.',
    incorrectUserWarningLink4: 'If you still see the wrong name, please contact your manager.',
    selectBrandAndDateFirstDose: ' Select the brand and date of the first dose:',
    selectBrandAndDateSecondDose: 'If you\'ve received your second dose, select the brand and date:',
    selectBrand: 'Select the brand...',
    selectDate: 'Select a date...',
    uploadOnMobile: '<b>ON MOBILE:</b> Tap to open your camera, upload a saved photo, or find a PDF on your device.',
    uploadOnMobileSodexo: '<b>ON MOBILE:</b> Tap to open your camera, upload a saved photo, or find a PDF on your device.',
    uploadOnComputer: '<b>ON COMPUTER:</b> Click to browse for an image, file, or PDF, or drag the file into the box below.',
    uploadVaccinationCopyTitle: 'Upload a copy of your vaccination documentation',
    uploadVaccinationCopyTitleForBloomin: 'Upload a copy of your vaccination documentation below',
    uploadVaccinationCopySubtitle: 'You can take a picture of the card or your self-attestation form, upload a photo you saved, upload a screenshot of an email, or attach a PDF you\'ve received.',
    uploadVaccinationCopySubtitleSodexo: 'You can upload a photo of your CDC COVID-19 Vaccination Record Card, a screenshot of an email, or a PDF of an official vaccine document.',
    uploadVaccinationCopySubtitleForBloomin: 'Please cover your date of birth or any other personal info besides your name and vaccination information. (Most people can cover it with their finger on the card!) You can take a picture of the card, or upload a saved photo, a screenshot, or a PDF.',
    uploadAreaInfo: 'Tap To Upload or Drag Your File Here',
    uploadVaccinationInstruction1: 'If you received a physical card, you can take a picture of it and upload the file above. If you are doing this on a mobile phone, you can tap the upload button above and you\'ll have the option to open your phone\'s camera.',
    uploadVaccinationInstruction1ForBlaze: 'If you received a physical card or if you have a Self-Attestation Form, you can take a picture of it and upload the file above. If you are doing this on a mobile phone, you can tap the upload button above and you\'ll have the option to open your phone\'s camera.',
    uploadVaccinationInstruction1Sodexo: 'You can only upload one document. If you are adding your second dose or booster, please replace the existing file with a new uploaded file/picture that includes all doses.',
    uploadVaccinationInstruction1Flynnrg: 'Acceptable proof of vaccination includes, The record of immunization from a health care provider or pharmacy, a copy of the U.S. COVID-19 Vaccination Record Card, a copy of medical records documenting the vaccination, a copy of immunization records from a public health, state, or tribal immunization information system, or a copy of any other official documentation that contains the type of vaccine administered, date(s) of administration, and the name of the health care professional(s) or clinic site(s) administering the vaccine(s).',
    uploadVaccinationInstruction2: 'If you received your verification as a PDF, you may upload that above.',
    uploadVaccinationInstruction3: 'If you only received an EMAIL verification, please take a screenshot and upload that above.',
    saveYourChanges: 'SAVE YOUR CHANGES',
    // Upload page with vaccine
    vaccinationRecords: 'Vaccination Records',
    vaccinationRecordsUser: 'Vaccination information has been successfully entered. The details of this are hidden for privacy reasons.',
    firstDose: 'First Dose',
    secondDose: 'Second Dose',
    thirdDose: 'Third Dose',
    fourthDose: 'Fourth Dose',
    fifthDose: 'Fifth Dose',
    sixthDose: 'Sixth Dose',
    delete: 'DELETE',
    deleteRecords: 'DELETE RECORDS',
    deleteFirstDose: 'Delete My First Dose Record',
    firstDoseAlreadyUploaded: 'First Dose Vaccination information has already been entered. The details of this are hidden for privacy reasons.',
    ifVaccinationIncorrect: 'If any of the above vaccine information is incorrect, please delete your record by clicking "DELETE RECORDS" and enter your information again.',
    ifVaccinationIncorrectUser: 'If you need to correct or update your vaccination information, please press the "Delete" button above and upload the new information again.',
    ifTestIncorrectUser: 'If you need to correct or update your tests information, please press the "Delete" button above and upload the new information again.',
    effectivenessReminder1: 'Remember, it takes time for your vaccine to achieve maximum effectiveness (at least',
    effectivenessReminder1Flynnrg: 'You are not considered fully vaccinated until at least two weeks have passed from your first or second vaccination dose in a one or two-shot vaccine series, respectively. Until you are fully vaccinated, you must continue to wear a face covering at all times while working.',
    effectivenessReminder2: 'days until you are fully protected). After your vaccine, please continue to wear a mask, practice social distancing, take required daily wellness checks and report any symptoms of illness.',
    logoutOrCreateNewRecord: 'Log Out or Create A New Vaccine Record',
    selectEmployeeLabel: 'Please select your name or name of your employee:',
    selectEmployeePlaceHolder: 'select the employee',
    selectRestaurantLabel: 'Select Your Restaurant',
    selectRestaurantLabelSodexo: 'Select Your Unit',
    selectLocationLabel: 'Select Your Location',
    selectDepartmentLabel: 'Select Your Department',
    selectResult: 'Select the result...',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    validationError: 'Validation error..',
    selectFirstDoseVax: 'Please select the first dose vaccine!',
    selectFirstDoseDate: 'Please select first dose date!',
    selectSecondDoseVax: 'Please select the second dose vaccine!',
    selectBoosterDoseVax: 'Please select booster dose vaccine!',
    somethingWentWrong: 'Something went wrong',
    missingCard: 'You\'re missing your vaccination card!',
    mustUpload: 'You must upload a picture or PDF of your vaccination verification',
    loginWelcome: 'Welcome to the ZHH Vaccination Portal!',
    loginWelcomeSodexo: 'Welcome to the Sodexo Vaccination Portal!',
    loginInstruction_employeeId: 'Enter your employee ID below to get started. ',
    loginInstruction_employeeIdSodexo: 'Frontline employees: Login here using your employeeID and birth year with no spaces (i.e. if your employee ID is 2839174 and your birth year is 1970, enter 28391741970).',
    loginInstruction_employeeIdCitybbq: 'Enter your File# below to get started. ',
    loginInstruction_employeeIdForBrinker: 'Please enter your employee number below to get started. If you do not know your employee number, you can locate it on your pay stub or contact your Manager.',
    loginInstruction_employeeIdForFlynnrg: 'Enter your Flynn People Portal User ID below to get started. If you don’t remember or know your User ID, your supervisor can help.',
    loginInstruction_employeeIdCallaway: 'Enter your 6-digit employee ID below to get started. An authentication email will be sent to your Company email address, then click on the link within the email.',
    loginInstruction_employeeEmail: 'Please enter the email you use for your daily wellness checks below: ',
    loginInstruction_employeeEmailForBlaze: 'Please enter your email address to get started: ',
    loginWarning_employeeId: 'If the name you see on the next page isn’t you, it means you entered the wrong ID. Please click the logout link and try entering your employee ID again.',
    loginWarning_employeeIdSodexo: 'If the name you see on the next page isn’t you, it means you entered the wrong information. Please click the logout link and try entering your employee ID and birth year again.',
    loginWarning_employeeIdCitybbq: 'If the name you see on the next page isn’t you, it means you entered the wrong File#. Please click the logout link and try entering your File# again.',
    login: 'Login',
    uploadError: 'There was an error uploading your file!',
    uploadErrorInst: 'Please try reducing the image size. If you still are unsuccessful, please email your name, employee ID, and image to covid@zerohourhealth.com.',
    areYouSure: 'Are you sure?',
    noRevert: 'You won\'t be able to revert this!',
    deleteConfirm: 'Yes, delete it!',
    submitSuccess: 'Your vaccination has been logged in the system.',
    partialSubmitSuccess: 'Thank you for uploading your first dose! Don\'t forget to come back here after your second dose and upload your up-to-date card.',
    success: 'Success',
    secondDoseInstr: 'Please select a date from the date picker, then try submitting again.',
    secondDoseWarning: 'Second dose date can\'t be before the first dose date!',
    secondDoseDateMissing: 'You\'re missing your second dose date!',
    boosterDoseDateMissing: 'You\'re missing your booster dose date!',
    disclaimer: 'Submitting your vaccination and testing records here is voluntary. I attest that the information submitted is valid. If I am a minor, I have obtained parental consent to provide this information.',
    disclaimerBlackbear: 'Submitting your vaccination record here is voluntary. Vaccination information will be shared with your employer. If you are entering COVID testing information it is important to use the same email address each time you start so all your records will be in the same place. ',
    disclaimerTAndM: 'Please submit your vaccination record here through the vaccination portal.',
    disclaimerSodexo: 'I attest that the information submitted is valid. If I am a minor, I have obtained parental consent to provide this information.',
    disclaimerFlynnrg: 'By entering into this portal, I attest that the information submitted in this portal is true and correct. I understand that an employee who knowingly submits false documents can be subject to disciplinary action and criminal penalties under federal law in the form of fines of up to $10,000, imprisonment of up to 6 months, or both. If I am a minor, I have obtained parental consent to provide this information.',
    selectResultPositive: 'POSITIVE',
    selectResultNegative: 'NEGATIVE',
    managerButtonInstructionsRaisingcanes: 'OR',
    managerButtonLabelSodexo: 'Manager Login',
    managerButtonLabelRaisingcanes: 'Login with Raising Cane\'s SSO',
    covidTestHistory: 'COVID-19 Test History',
    managerButtonInstructionsSodexo: 'Managers: Login here using your Sodexo email for Single Sign-On',
    ...en_ErrorMessages,
    ...en_LoginPage,
    ...en_HomePage,
    ...en_UploadVaccinationPage,
    ...en_UploadHepVaccinationPage,
    ...en_UploadCovidTestPage,
    ...en_DashboardPage,
    ...en_NavigationMenu,
    ...en_CovidVaxNoRecordUpload,
    ...en_HepProgramPages,
  },
  es: {
    // Upload page without vaccine
    employeeNameAndID: 'Nombre + ID del empleado:',
    employeeEmailLabel: 'Correo electrónico',
    uploadAdminScreenWarning: 'Esta es una pantalla de administrador para ingresar manualmente la verificación para sus empleados. Si Ud. no es administrador o no desea ver esta pantalla',
    clickHereToLogin: 'haga clic aquí para volver a la pantalla de inicio de sesión.',
    incorrectUserWarningLink1: 'Si Ud. no es',
    incorrectUserWarningLink2_employeeId: 'Ud. ingresó un número de identificación de empleado incorrecto.',
    incorrectUserWarningLink2_employeeIdSodexo: 'significa que ingresó una identificación de empleado y un año de nacimiento incorrectos.',
    incorrectUserWarningLink2_employeeEmail: 'Verifique la dirección de correo electrónico que ingresó.',
    incorrectUserWarningLink3_employeeId: 'Haga clic aquí para intentar iniciar sesión nuevamente.',
    incorrectUserWarningLink3_employeeEmail: 'Si no es correcto, haga clic aquí para volver y empezar de nuevo.',
    incorrectUserWarningLink4: 'Si aún ve el nombre incorrecto, comuníquese con su gerente para confirmar su número de identificación.',
    selectBrandAndDateFirstDose: 'Seleccione la marca y la fecha de la primera dosis:',
    selectBrandAndDateSecondDose: 'Si ha recibido su segunda dosis, seleccione la marca y la fecha:',
    selectBrand: 'Seleccione la marca...',
    selectDate: 'Seleccione una fecha...',
    uploadOnMobile: '<b>EN MÓVIL:</b> Toca para abrir tu cámara, cargar una foto guardada o buscar un PDF en tu dispositivo.',
    uploadOnMobileSodexo: '<b>EN MÓVIL:</b> Abra su cámara, suba una foto guardada o busque un PDF en su dispositivo móvil.',
    uploadOnComputer: '<b>EN EL ORDENADOR:</b> Haga clic para buscar una imagen, archivo o PDF, o arrastre el archivo al cuadro de abajo.',
    uploadVaccinationCopyTitle: 'Cargue una copia de su documentación de vacunación',
    uploadVaccinationCopyTitleForBloomin: 'Cargue una copia de su documentación de vacunación abajo',
    uploadVaccinationCopySubtitle: 'Puede tomar una foto de la tarjeta o su formulario de autodeclaración, cargar una foto guardada, cargar una captura de pantalla de un correo electrónico o adjuntar un PDF que haya recibido.',
    uploadVaccinationCopySubtitleSodexo: 'Puede cargar una foto de su Tarjeta de registro de vacunación contra el COVID-19 de los CDC, una captura de pantalla de un correo electrónico o un PDF de un documento oficial de vacuna.',
    uploadVaccinationCopySubtitleForBloomin: 'Cubre tu fecha de nacimiento o cualquier otra información personal además de tu nombre y la información de vacunación. (¡La mayoría de la gente puede cubrirla con el dedo en la tarjeta!) Puede tomar una foto de la tarjeta o cargar una foto guardada, una captura de pantalla o un PDF.',
    uploadAreaInfo: 'Toque para cargar o arrastre su archivo aquí',
    uploadVaccinationInstruction1: 'Si recibió una tarjeta física, tome una foto y cárguela de arriba. Si está en un teléfono móvil, puede tocar el botón de carga de arriba y tendrá la opción de abrir la cámara de su teléfono.',
    uploadVaccinationInstruction1ForBlaze: 'Si recibió una tarjeta física o si tiene un formulario de autodeclaración, puede tomar una foto y cargar el archivo de arriba. Si está haciendo esto en un teléfono móvil, puede tocar el botón de carga de arriba y tendrá la opción de abrir la cámara de su teléfono.',
    uploadVaccinationInstruction1Sodexo: 'Solo puede cargar un documento. Si está agregando su segunda dosis o refuerzo, reemplace el archivo existente con un nuevo archivo / imagen cargada que incluya todas las dosis.',
    uploadVaccinationInstruction1Flynnrg: 'La lista de pruebas de vacunación aceptables incluye: El registro de vacunación de un proveedor de atención médica o farmacia, una copia de la Tarjeta de registro de vacunación COVID-19 de EE. UU., Una copia de los registros médicos que documenten la vacunación, una copia de los registros de vacunación de un estado o el sistema de información de inmunización tribal, o una copia de cualquier otra documentación oficial que contenga el tipo de vacuna administrada, la(s) fecha(s) de administración y el nombre del (los) profesional(es) de atención médica o el(los) centro(s) de la clínica que administró la(s) vacuna(s).',
    uploadVaccinationInstruction2: 'Si recibió su verificación en formato PDF, puede cargarla arriba.',
    uploadVaccinationInstruction3: 'Si solo recibió una verificación por CORREO ELECTRÓNICO, tome una captura de pantalla y cárguela arriba.',
    saveYourChanges: 'GUARDE SUS CAMBIOS',
    // Upload page with vaccine
    vaccinationRecords: 'Registros de vacunación',
    vaccinationRecordsUser: 'La información de vacunación se cargó correctamente. Los detalles de esto están ocultos por razones de privacidad.',
    firstDose: 'Primera dosis',
    secondDose: 'Segunda dosis',
    thirdDose: 'Tercera dosis',
    fourthDose: 'Cuarta dosis',
    fifthDose: 'Quinta dosis',
    sixthDose: 'Sexta dosis',
    delete: 'ELIMINAR',
    deleteRecords: 'BORRAR REGISTROS',
    deleteFirstDose: 'ELIMINAR',
    firstDoseAlreadyUploaded: 'La información de la vacuna de la primera dosis ya se ha ingresado. Los detalles de esto están ocultos por razones de privacidad.',
    ifVaccinationIncorrect: 'Si alguna de la información de vacunación anterior es incorrecta, por favor elimine su registro haciendo clic en "BORRAR REGISTROS" e ingrese su información nuevamente.',
    ifVaccinationIncorrectUser: 'Si necesita corregir o actualizar su información de vacunación, presione el botón "Eliminar" arriba y cargue la nueva información otra vez.',
    ifTestIncorrectUser: 'Si necesita corregir o actualizar su información de prueba, presione el botón "Eliminar" arriba y cargue la nueva información otra vez.',
    effectivenessReminder1: 'Recuerde, se necesita tiempo para que su vacuna alcance la máxima eficacia (al menos',
    effectivenessReminder1Flynnrg: 'No se considera que está completamente vacunado hasta que hayan pasado al menos dos semanas desde su primera o segunda dosis de vacunación en una serie de vacunas de una o dos inyecciones, respectivamente. Hasta que esté completamente vacunado, debe continuar usando una cubierta facial en todo momento mientras trabaja.',
    effectivenessReminder2: 'días hasta que esté completamente protegido). Después de su vacuna, continúe usando una máscara, practique el distanciamiento social, realice las encuestas de bienestar diarias, e informe cualquier síntoma de enfermedad.',
    logoutOrCreateNewRecord: 'Cerrar sesión o crear un nuevo registro de vacunas',
    selectEmployeeLabel: 'Selecione o seu nome ou o nome do seu empleado:',
    selectEmployeePlaceHolder: 'Seleccione el empleado...',
    selectRestaurantLabel: 'Seleccione tu restaurante',
    selectRestaurantLabelSodexo: 'Seleccione tu unidad',
    selectLocationLabel: 'Selecciona tu ubicación',
    selectDepartmentLabel: 'Selecciona tu departamento',
    selectResult: 'Seleccione el resultado...',
    firstNameLabel: 'Primer Nombre',
    lastNameLabel: 'Apellido',
    validationError: 'Error de validación...',
    selectFirstDoseVax: '¡Seleccione la primera dosis de vacuna!',
    selectFirstDoseDate: '¡Seleccione la fecha de la primera dosis!',
    selectSecondDoseVax: '¡Seleccione la segunda dosis de vacuna!',
    selectBoosterDoseVax: '¡Seleccione la vacuna de dosis de refuerzo!',
    somethingWentWrong: 'Algo salió mal',
    missingCard: '¡Falta su cartilla de vacunación!',
    mustUpload: 'Debe cargar una imagen o PDF de su verificación de vacunación',
    loginWelcome: '¡Bienvenido al portal de vacunación ZHH-Zedic COVID!',
    loginWelcomeSodexo: '¡Bienvenido al portal de vacunación de Sodexo!',
    loginInstruction_employeeId: 'Ingrese su ID de empleado a continuación para comenzar.',
    loginInstruction_employeeIdSodexo: 'Empleados de primera línea: inicie sesión aquí con su ID de empleado y año de nacimiento sin espacios (es decir, si su ID de empleado es 2839174 y su año de nacimiento es 1970, introduzca 28391741970).',
    loginInstruction_employeeIdCitybbq: 'Ingrese su ID de empleado a continuación para comenzar.',
    loginInstruction_employeeIdForBrinker: 'Ingrese su número de empleado a continuación para comenzar. Si no conoce su número de empleado, puede localizarlo en su talón de pago o comunicarse con su Gerente.',
    loginInstruction_employeeIdForFlynnrg: 'Ingrese su ID de usuario del Portal Flynn People a continuación para comenzar. Si no recuerda o no conoce su identificación de usuario, su supervisor puede ayudarlo.',
    loginInstruction_employeeIdCallaway: 'Ingrese su identificación de empleado de 6 dígitos a continuación para comenzar. Se enviará un correo electrónico de autenticación a la dirección de correo electrónico de su empresa, luego haga clic en el enlace dentro del correo electrónico.',
    loginInstruction_employeeEmail: 'Ingrese el correo electrónico que utiliza para sus encuestas de bienestar diarias a continuación:',
    loginInstruction_employeeEmailForBlaze: 'Ingrese su dirección de correo electrónico para comenzar: ',
    loginWarning_employeeId: 'Si el nombre que ve en la página siguiente no es usted, ingresó una identificación incorrecta. Haga clic en el enlace para cerrar sesión e intente ingresar su ID de empleado nuevamente.',
    loginWarning_employeeIdSodexo: 'Si el nombre que ve en la página siguiente no es usted, significa que ingresó la información incorrecta. Haga clic en el enlace para cerrar sesión e intente ingresar nuevamente su identificación de empleado y año de nacimiento.',
    loginWarning_employeeIdCitybbq: 'Si el nombre que ve en la página siguiente no es usted, ingresó una identificación incorrecta. Haga clic en el enlace para cerrar sesión e intente ingresar su ID de empleado nuevamente.',
    login: 'Iniciar sesión',
    uploadError: '¡Hubo un error al cargar su archivo!',
    uploadErrorInst: 'Reduzca el tamaño de la imagen. Si aún no tiene éxito, envíe un correo electrónico con su nombre, identificación de empleado e imagen a covid@zerohourhealth.com',
    areYouSure: '¿Está seguro?',
    noRevert: '¡No podrá revertir esto!',
    deleteConfirm: '¡Sí, bórralo!',
    success: 'Éxito',
    submitSuccess: 'Su vacunación se ha registrado en el sistema.',
    partialSubmitSuccess: '¡Gracias por cargar su primera dosis! No olvide volver aquí después de su segunda dosis para cargar su tarjeta actualizada.',
    secondDoseInstr: 'Seleccione una fecha del selector de fechas e intente enviar de nuevo.',
    secondDoseWarning: '¡La fecha de la segunda dosis no puede ser anterior a la fecha de la primera dosis!',
    secondDoseDateMissing: '¡Falta la fecha de la segunda dosis!',
    boosterDoseDateMissing: '¡Falta la fecha de la dosis de refuerzo!',
    disclaimer: 'El envío de sus registros de vacunación y pruebas de COVID aquí es voluntario. Confirmo que la información enviada es válida. Si soy menor de edad, he obtenido el consentimiento de mis padres para dar esta información.',
    disclaimerSodexo: 'Confirmo que la información enviada es válida. Si soy menor de edad, he obtenido el consentimiento de mis padres para dar esta información.',
    disclaimerBlackbear: 'El envío de su registro de vacunación aquí es voluntario. La información de vacunación se compartirá con su empleador. Si ingresa la información de la prueba COVID, es importante que use la misma dirección de correo electrónico cada vez que comience para que todos sus registros estén en el mismo lugar.',
    disclaimerTAndM: 'Envíe su registro de vacunación aquí a través del portal de vacunación.',
    disclaimerFlynnrg: 'Al ingresar a este portal, doy fe de que la información presentada en este portal es verdadera y correcta. Entiendo que un empleado que presente documentos falsos a sabiendas puede estar sujeto a medidas disciplinarias y sanciones penales según la ley federal en forma de multas de hasta $ 10,000, encarcelamiento de hasta 6 meses o ambos. Si soy menor de edad, he obtenido el consentimiento de los padres para proporcionar esta información.',
    selectResultPositive: 'POSITIVO',
    selectResultNegative: 'NEGATIVO',
    managerButtonInstructionsRaisingcanes: 'O',
    managerButtonLabelSodexo: 'Inicio de sesión del administrador',
    managerButtonLabelRaisingcanes: 'Iniciar sesión con el SSO de Raising Canes',
    covidTestHistory: 'Historial de pruebas de COVID-19',
    managerButtonInstructionsSodexo: 'Gerentes: inicie sesión aquí usando su correo electrónico de Sodexo para el inicio de sesión único',
    ...es_ErrorMessages,
    ...es_LoginPage,
    ...es_HomePage,
    ...es_UploadVaccinationPage,
    ...es_UploadHepVaccinationPage,
    ...es_UploadCovidTestPage,
    ...es_DashboardPage,
    ...es_NavigationMenu,
    ...es_CovidVaxNoRecordUpload,
    ...es_HepProgramPages,
  },
};

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages, // set locale messages
});

export default i18n;
