<template>
  <div class="page-block-item">
    <img class="group-logo" src="/vaccine/images/logo/zhh_group_logo.png">
    <p class="page-title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: String,
  },
};
</script>

<style scoped>

  .group-logo {
    margin-bottom: 75px;
    width:600px;
  }

  @media screen and (max-width: 479px) {
    .group-logo {
      margin-bottom: 50px;
    }
  }
</style>
